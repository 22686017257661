import React from "react";
import { addFavorite, removeFavorite } from "../actions";

class MovieCard extends React.Component {
  handleFavoriteClick = () => {
    const { movie } = this.props;
    this.props.dispatch(addFavorite(movie));
  };

  handleUnfavoriteClick = () => {
    const { movie } = this.props;
    this.props.dispatch(removeFavorite(movie));
  };

  render() {
    const { movie, isFavorite } = this.props;

    return (
      <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        {/* Poster */}
        <img
          src={movie.Poster}
          alt={`${movie.Title} Poster`}
          className="w-full h-64 object-cover"
        />

        {/* Content */}
        <div className="p-6">
          <h3 className="text-2xl font-bold text-white">{movie.Title}</h3>
          <p className="text-gray-400 text-sm mb-2">{movie.Year}</p>
          <p className="text-gray-300 text-sm italic mb-4">{movie.Plot}</p>

          {/* Movie Stats */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <p className="text-sm font-bold text-indigo-400">Released:</p>
              <p className="text-gray-300 text-sm">{movie.Released}</p>
            </div>
            <div>
              <p className="text-sm font-bold text-indigo-400">Box Office:</p>
              <p className="text-gray-300 text-sm">{movie.BoxOffice}</p>
            </div>
            <div>
              <p className="text-sm font-bold text-indigo-400">IMDb Rating:</p>
              <p className="text-gray-300 text-sm">{movie.imdbRating}</p>
            </div>
            <div>
              <p className="text-sm font-bold text-indigo-400">Votes:</p>
              <p className="text-gray-300 text-sm">{movie.imdbVotes}</p>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-between items-center">
            {isFavorite ? (
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                onClick={this.handleUnfavoriteClick}
              >
                Unfavorite
              </button>
            ) : (
              <button
                className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
                onClick={this.handleFavoriteClick}
              >
                Favorite
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MovieCard;
