import React from "react";
import { connect } from "react-redux";
import { addMovieToList, handleMovieSearch } from "../actions";
import { FiSearch } from "react-icons/fi"; // Importing a search icon

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      showSearchResults: false,
    };
  }

  handleAddToMovies = (movie) => {
    this.props.dispatch(addMovieToList(movie));
    this.setState({
      showSearchResults: false,
    });
  };

  handleSearch = () => {
    const { searchText } = this.state;
    if (searchText.trim()) {
      this.props.dispatch(handleMovieSearch(searchText));
      this.setState({
        showSearchResults: true,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  render() {
    const { searchText, showSearchResults } = this.state;
    const { result: movie } = this.props.search;

    return (
      <div className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-white py-4 shadow-lg fixed w-full z-50">
        <div className="container mx-auto px-4 flex items-center justify-between">
          {/* Logo */}
          <h1 className="text-4xl font-bold tracking-wide hidden sm:block">Movie Store</h1>
          <h1 className="text-3xl font-bold tracking-wide sm:hidden block">MS</h1>

          {/* Search Box */}
          <div className="relative w-full max-w-md">
            <div className="flex items-center bg-gray-800 rounded-full shadow-md">
              <input
                type="text"
                placeholder="Search for movies..."
                className="w-full px-4 py-2 bg-transparent text-white placeholder-gray-400 focus:outline-none rounded-l-full"
                value={searchText}
                onChange={this.handleChange}
              />
              <button
                onClick={this.handleSearch}
                className="bg-purple-500 p-3 rounded-r-full hover:bg-purple-600 transition"
              >
                <FiSearch size={20} className="text-white" />
              </button>
            </div>
          </div>
        </div>

        {/* Search Results */}
        {showSearchResults && movie && (
          <div className="mt-4 px-4">
            <div className="bg-gray-800 text-white rounded-xl shadow-md p-4 flex flex-col sm:flex-row items-center sm:items-start space-x-0 sm:space-x-6">
              {/* Movie Poster */}
              <img
                src={movie.Poster}
                alt="Movie Poster"
                className="w-32 h-48 object-cover rounded-lg shadow-sm mb-4 sm:mb-0 sm:w-40 sm:h-56"
              />
              {/* Movie Details */}
              <div className="flex-1 text-center sm:text-left">
                <h2 className="text-xl font-bold">{movie.Title}</h2>
                <p className="text-sm text-gray-300 mt-2">{movie.Plot}</p>
                {/* Additional Details */}
                <div className="flex flex-col sm:flex-row sm:space-x-8 mt-4">
                  <div className="text-center mb-4 sm:mb-0">
                    <span className="block text-sm text-gray-400">Release</span>
                    <span className="block text-base font-medium">{movie.Released}</span>
                  </div>
                  <div className="text-center mb-4 sm:mb-0">
                    <span className="block text-sm text-gray-400">Box Office</span>
                    <span className="block text-base font-medium">{movie.BoxOffice}</span>
                  </div>
                  <div className="text-center">
                    <span className="block text-sm text-gray-400">Rating</span>
                    <span className="block text-base font-medium">{movie.imdbRating}</span>
                  </div>
                </div>
                {/* Action Button */}
                <button
                  onClick={() => this.handleAddToMovies(movie)}
                  className="mt-6 bg-purple-500 px-4 py-2 text-sm rounded-md hover:bg-purple-600 transition"
                >
                  Add to Movies
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStoreToProps = ({ search }) => {
  return {
    search: search,
  };
};

export default connect(mapStoreToProps)(Navbar);
