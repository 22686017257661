import React from "react";
import { connect } from "react-redux";
import { data } from "../data";
import { Navbar, MovieCard } from "./";
import { addMovies, setShowFavorites } from "../actions";

class App extends React.Component {
  componentDidMount() {
    this.props.dispatch(addMovies(data));
  }

  isMovieFavorite = (movie) => {
    const { movies } = this.props;
    return movies.favorites.includes(movie);
  };

  onChangeTab = (val) => {
    this.props.dispatch(setShowFavorites(val));
  };

  render() {
    const { movies, search } = this.props;
    const { list, favorites, showFavourites } = movies;
    const displayMovies = showFavourites ? favorites : list;

    return (
      <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-800 text-white">
        {/* Navbar */}
        <div className="pb-20">
        <Navbar search={search} />
        </div>

        <div className="container mx-auto px-4 py-6 lg:px-8">
          {/* Tabs */}
          <div className="flex justify-center mb-8">
            <button
              className={`px-6 py-3 text-lg font-bold rounded-md transition-all ${
                !showFavourites
                  ? "bg-indigo-600 text-white shadow-lg shadow-indigo-500/50"
                  : "bg-gray-700 hover:bg-gray-600"
              }`}
              onClick={() => this.onChangeTab(false)}
            >
              Movies
            </button>
            <button
              className={`ml-4 px-6 py-3 text-lg font-bold rounded-md transition-all ${
                showFavourites
                  ? "bg-indigo-600 text-white shadow-lg shadow-indigo-500/50"
                  : "bg-gray-700 hover:bg-gray-600"
              }`}
              onClick={() => this.onChangeTab(true)}
            >
              Favorites
            </button>
          </div>

          {/* Movie Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {displayMovies.length > 0 ? (
              displayMovies.map((movie, index) => (
                <MovieCard
                  key={`movies-${index}`}
                  movie={movie}
                  dispatch={this.props.dispatch}
                  isFavorite={this.isMovieFavorite(movie)}
                />
              ))
            ) : (
              <div className="col-span-full text-center text-xl font-medium text-gray-400">
                No movies to display!
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    movies: state.movies,
    search: state.search,
  };
}

const connectedAppComponent = connect(mapStateToProps)(App);

export default connectedAppComponent;
